import { LazyLoadImage } from "react-lazy-load-image-component";
import { AboutHero, HomeHero, SampleHero } from "../../assets/images";
import Button from "../element/button";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import useWindowDimensions from "../hook/use.window.dimensions";

export default function HeaderImage({ locateAt }) {
  const { width } = useWindowDimensions();

  const headerImage = {
    home: {
      title: "UNLEASH A NEW LOOK <br /> DEFEND EVERY MILE",
      subtitle:
        "PREMIUM PROTECTION • EXPERT CRAFTSMANSHIP • GUARANTEED SATISFACTION",
      button: "TALK TO US",
      bg_text: "JUSTINT JUSTINT JUSTINT",
      image: HomeHero,
    },
    "about-us": {
      title: "DRIVEN BY PASSION <br /> FUELED BY EXPERTISE",
      subtitle: "QUALITY SERVICE • TRUSTED EXPERTISE • LASTING RELATIONSHIPS",
      button: "LEARN MORE",
      bg_text: "ESTABLISHED 2005",
      image: AboutHero,
    },
  };

  const handleRedirectWhatsapp = () => {
    const phoneNumber = "60122334402";
    const message =
      "Hi, I have an enquiry about your services and would like some assistance.";
    const whatsappURL = `https://wa.me/`;

    window.open(
      whatsappURL + phoneNumber + "?text=" + encodeURIComponent(message)
    );
  };

  return (
    <article className="w-100">
      <motion.section
        className={`common-hero --${locateAt}`}
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // transition={{ duration: 1 }}
      >
        <motion.img
          src={headerImage[locateAt].image}
          alt={headerImage[locateAt].title}
          // initial={{ opacity: 0, y: 50 }}
          // animate={{ opacity: 1, y: 0 }}
          // transition={{ duration: 1, delay: 0.5 }}
        />
        <article className="hero-text">
          <motion.h2
            dangerouslySetInnerHTML={{ __html: headerImage[locateAt].title }}
            // initial={{ opacity: 0 }}
            // animate={{ opacity: 1 }}
            // transition={{ duration: 1, delay: 1 }}
          />
          <motion.hr
          // initial={{ width: 0 }}
          // animate={{ width: width > 550 ? '16%' : '30%' }}
          // transition={{ duration: 0.8, delay: 1.5 }}
          />
          <motion.p
          // initial={{ opacity: 0, x: 50 }}
          // animate={{ opacity: 1, x: 0 }}
          // transition={{ duration: 1, delay: 1.8 }}
          >
            {headerImage[locateAt].subtitle}
          </motion.p>
          <motion.section
            className="scrolling-text"
            // initial={{ opacity: 0, scale: 0.8 }}
            // animate={{ opacity: 1, scale: 1 }}
            // transition={{ duration: 1, delay: 1.2 }}
          >
            <Marquee
              speed={90}
              autoFill={true}
              // gradient={true}
              // gradientWidth={15}
            >
              <h1>&nbsp;{headerImage[locateAt].bg_text}&nbsp;</h1>
            </Marquee>
          </motion.section>
          <motion.section
          // initial={{ opacity: 0, scale: 0.8 }}
          // animate={{ opacity: 1, scale: 1 }}
          // transition={{ duration: 1, delay: 2 }}
          >
            <Button
              btnClassName={`talk-button ${locateAt}`}
              onClick={() => handleRedirectWhatsapp()}
            >
              {headerImage[locateAt].button}
            </Button>
          </motion.section>
        </article>
      </motion.section>
    </article>
  );
}
