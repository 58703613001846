import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/element/button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CustomerDetail from "./customer.detail";
import { Form, Formik } from "formik";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import InputCount from "../../../components/element/input.count";
import {
  openAccessoriesModal,
  openEditPriceModal,
} from "../../../redux/reducer/modalReducer";
import { useEffect, useRef, useState } from "react";
import {
  createOrder,
  getCartInfo,
  getOrderList,
  removeCart,
  setCartItemLoading,
  setSelectedCartItem,
  updateCart,
} from "../../../redux/reducer/cartOrderReducer";
import { getProductInfo } from "../../../redux/reducer/serviceReducer";
import * as Yup from "yup";
import {
  getCartCount,
  getUserRole,
} from "../../../redux/reducer/commonReducer";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../components/common/common.toast";
import { DefaultJustint } from "../../../assets/images";
import Lottie from "lottie-react";
import { Empty, Loading } from "../../../assets/lotties";

export const BodyHeader = ({ icon, title, className }) => {
  return (
    <section className="body-header">
      <article className="pt-2 ps-4 flex items-center gap-3">
        <Icon icon={icon} className={className ?? ""} />
        <h6>{title}</h6>
      </article>
      <hr />
    </section>
  );
};

export const TotalPriceRow = ({ cartInfo, loading, buttonTitle }) => {
  const handleNext = () => {
    const nextSection = document.getElementById("customer-details");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (buttonTitle === "Next" && cartInfo?.total_item > 0) ||
    buttonTitle !== "Next" ? (
    <section className="total-price-row">
      <article className="flex items-end gap-4">
        <p>Total ({cartInfo?.items?.length} Items)</p>
        <h6>
          &nbsp; RM{" "}
          {isNaN(cartInfo?.total)
            ? 0
            : parseFloat(cartInfo?.total).toLocaleString()}
        </h6>
      </article>
      <Button
        disabled={!cartInfo || cartInfo?.total_item === 0 || loading}
        btnClassName="common-button --without-icon"
        type={buttonTitle === "Next" ? "button" : "submit"}
        onClick={() => (buttonTitle === "Next" ? handleNext() : "")}
      >
        {buttonTitle}
      </Button>
    </section>
  ) : (
    <></>
  );
};

export default function CartBody() {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const remarkRef = useRef(null);

  const [editRemark, setEditRemark] = useState(null);

  const { cartInfo, cartLoading, cartItemLoading, createOrderLoading } =
    useSelector((state) => state.cart_order);
  const { role, token, username } = useSelector((state) => state.common);

  const cartForm = {
    name: "",
    contact: "",
    car_chassis_number: "",
    car_no_plate: "",
    note: "",
  };

  const cartSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contact: Yup.string()
      .matches(/^[1-9]\d{7,9}$/, "Please enter a valid phone number")
      .required("Contact no is required"),
  });

  const handleSubmit = (values, setFieldError, resetForm) => {
    dispatch(
      createOrder({
        ...values,
        contact: values.contact ? `+60${values.contact}` : "",
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(getCartCount());
        toast.success("Place order successfully");
        navigate("/order");
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
          }
        }
      });
  };

  const handleOpenEditPrice = (id, quantity, price, note, agent_price) => {
    dispatch(
      setSelectedCartItem({
        id: id,
        quantity: quantity,
        price: price,
        note: note,
        agent_price: agent_price,
      })
    );
    dispatch(openEditPriceModal());
  };

  const handleViewProduct = (id) => {
    dispatch(openAccessoriesModal());
    dispatch(
      getProductInfo({
        id: id,
      })
    );
  };

  const handleRemoveCart = (id) => {
    dispatch(
      removeCart({
        id: id,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(getCartInfo());
        dispatch(getCartCount());
      })
      .catch((ex) => {
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            // Object.keys(errors).map((item, i) => {
            //     setFieldError(item, errors[item]);
            // });
          }
        }
      });
  };

  const handleUpdateCart = (id, quantity, price, note, type) => {
    dispatch(
      setCartItemLoading({
        id,
        type: type || null,
      })
    );

    dispatch(
      updateCart({
        id: id,
        quantity: quantity,
        price: price,
        note: note,
      })
    )
      .unwrap()
      .then((res) => {
        dispatch(getCartInfo());
      })
      .catch((ex) => {
        dispatch(setCartItemLoading(null));
        if (ex && ex.response?.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            // Object.keys(errors).map((item, i) => {
            //     setFieldError(item, errors[item]);
            // });
          }
        }
      });
  };

  const handleClickOutside = (event) => {
    if (remarkRef.current && !remarkRef.current.contains(event.target)) {
      if (editRemark?.id) {
        handleUpdateCart(
          editRemark.id,
          editRemark.quantity,
          editRemark.price,
          editRemark.remark,
          "update_note"
        );
        setEditRemark(null);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && editRemark?.id) {
      handleUpdateCart(
        editRemark.id,
        editRemark.quantity,
        editRemark.price,
        editRemark.remark,
        "update_note"
      );
      setEditRemark(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editRemark]);

  useEffect(() => {
    dispatch(getUserRole());

    if (token && username) {
      dispatch(getCartInfo());
    }
  }, [token, username]);

  return (
    <section className="cart-body">
      <Formik
        initialValues={cartForm}
        validationSchema={cartSchema}
        onSubmit={(values, { setFieldError }) => {
          handleSubmit(values, setFieldError);
        }}
      >
        {() => (
          <Form className="cart-form">
            <article className="form-section">
              <BodyHeader
                icon="akar-icons:basket"
                title="Order Details"
                className="--order"
              />
              <section className="cart-list">
                {!cartLoading || cartItemLoading !== null ? (
                  cartInfo?.items?.length > 0 ? (
                    cartInfo.items.map((cart, cartIndex) => (
                      <section>
                        <article
                          className={`cart-grid cart-content ${
                            width > 800 ? "mb-5" : ""
                          }`}
                          key={cartIndex}
                        >
                          <section className="image-col w-100">
                            <article
                              className={`flex ${
                                width < 800 ? "flex-wrap gap-1" : "gap-4 w-100"
                              } ${width < 600 ? "w-100" : ""} items-start`}
                            >
                              <section
                                className={
                                  width < 800
                                    ? `flex ${
                                        width < 600 ? "gap-0" : "gap-3"
                                      } items-end w-100 me-4`
                                    : ""
                                }
                              >
                                <article
                                  className={
                                    width > 800
                                      ? "flex flex-column items-center pt-2"
                                      : "w-100"
                                  }
                                >
                                  <section className="cart-frame">
                                    <LazyLoadImage
                                      src={
                                        cart.media?.length > 0
                                          ? cart.media[0].url
                                          : DefaultJustint
                                      }
                                    />
                                  </section>
                                  {cart.product.service === "accessory" &&
                                    width > 800 && (
                                      <Button
                                        className="mt-3"
                                        btnClassName="common-button"
                                        onClick={() =>
                                          handleViewProduct(cart.product_id)
                                        }
                                      >
                                        <article>
                                          <Icon icon="majesticons:search-line" />
                                        </article>
                                        <p>View</p>
                                      </Button>
                                    )}
                                </article>
                                {width < 800 && (
                                  <Button
                                    className="mt-3"
                                    btnClassName="common-button only-icon"
                                    onClick={() =>
                                      handleViewProduct(cart.product_id)
                                    }
                                  >
                                    <article>
                                      <Icon icon="majesticons:search-line" />
                                    </article>
                                  </Button>
                                )}
                              </section>
                              {width > 800 && (
                                <section className="w-100 pt-2 me-3">
                                  <article
                                    className={`${
                                      Object.keys(cart.options)?.length > 0 ||
                                      cart.note
                                        ? "mb-3"
                                        : ""
                                    } flex ${
                                      width < 800 ? "flex-column" : ""
                                    } justify-between gap-3`}
                                  >
                                    <h6 className="two-line">
                                      {cart.product.name?.name}
                                    </h6>
                                    <h6 className="pre">RM {cart.price}</h6>
                                  </article>
                                  {Object.keys(cart.options)?.length > 0 &&
                                    Object.keys(cart.options).map(
                                      (option, optionIndex) => (
                                        <article
                                          key={optionIndex}
                                          className="flex flex-wrap justify-between"
                                        >
                                          <p className="--option text-capitalize">
                                            {option?.replaceAll("_", " ")}
                                          </p>
                                          <p
                                            className={`--option ${
                                              width > 800 ? "text-end" : ""
                                            } text-capitalize`}
                                          >
                                            {cart.options[option].type},{" "}
                                            {cart.options[option].VLT}%
                                          </p>
                                        </article>
                                      )
                                    )}
                                  {cart.product.service === "accessory" &&
                                    cart.note &&
                                    (cartItemLoading?.id === cart.id &&
                                    cartItemLoading?.type ? (
                                      <article className="remark-loading">
                                        <Icon
                                          icon="eos-icons:three-dots-loading"
                                          className="--loading"
                                        />
                                      </article>
                                    ) : editRemark?.id === cart.id ? (
                                      <article
                                        ref={remarkRef}
                                        className="flex items-center gap-3 remark-input"
                                      >
                                        <Icon icon="ci:edit-pencil-line-01" />
                                        <input
                                          value={editRemark.remark}
                                          onChange={(e) =>
                                            setEditRemark({
                                              ...editRemark,
                                              remark: e.target.value,
                                            })
                                          }
                                        />
                                      </article>
                                    ) : (
                                      <p
                                        className="--option two-line line-height-20 pointer"
                                        onClick={() =>
                                          setEditRemark({
                                            id: cart.id,
                                            quantity: cart.quantity,
                                            price: cart.price,
                                            remark: cart.note,
                                          })
                                        }
                                      >
                                        <Icon icon="ci:edit-pencil-line-01" />
                                        {cart.note}
                                      </p>
                                    ))}
                                  {/* {cart.product.service === "accessory" &&
                                    !cart.note &&
                                    width > 800 && (
                                      <Button
                                        className="mt-3"
                                        btnClassName="common-button"
                                        onClick={() =>
                                          handleViewProduct(cart.product_id)
                                        }
                                      >
                                        <article>
                                          <Icon icon="majesticons:search-line" />
                                        </article>
                                        <p>View</p>
                                      </Button>
                                    )} */}
                                </section>
                              )}
                            </article>
                          </section>
                          {/* <p>{cart.price}</p> */}
                          <InputCount
                            handleUpdateCart={handleUpdateCart}
                            current_id={cart.id}
                            current_quantity={cart.quantity}
                            current_price={cart.price}
                            current_note={cart.note}
                          />
                          <p className="text-center">
                            {cart.price * cart.quantity}
                          </p>
                          <section
                            className={`flex gap-${width > 600 ? 3 : 2}`}
                          >
                            {role === "agent" && (
                              <article
                                onClick={() =>
                                  handleOpenEditPrice(
                                    cart.id,
                                    cart.quantity,
                                    cart.price,
                                    cart.note,
                                    cart.agent_price
                                  )
                                }
                              >
                                {width > 600 ? (
                                  <p className="--edit pointer">Edit Price</p>
                                ) : (
                                  <article className="edit-icon">
                                    <Icon icon="mdi:file-document-edit-outline" />
                                  </article>
                                )}
                              </article>
                            )}
                            <article
                              className={`delete-icon ${
                                role === "user" ? "border-0" : ""
                              } pointer`}
                              onClick={() => handleRemoveCart(cart.id)}
                            >
                              <Icon icon="basil:trash-solid" />
                            </article>
                          </section>
                        </article>
                        {width < 800 && (
                          <section className="cart-subgrid cart-content w-100 me-3">
                            <article
                              className={`${
                                Object.keys(cart.options)?.length > 0 ||
                                cart.note
                                  ? "mb-3"
                                  : ""
                              } flex justify-between gap-4`}
                            >
                              <h6 className="two-line">
                                {cart.product.name?.name}
                              </h6>
                              <h6 className="pre">RM {cart.price}</h6>
                            </article>
                            {Object.keys(cart.options)?.length > 0 &&
                              Object.keys(cart.options).map(
                                (option, optionIndex) => (
                                  <article
                                    key={optionIndex}
                                    className="flex flex-wrap justify-between"
                                  >
                                    <p className="--option text-capitalize">
                                      {option?.replaceAll("_", " ")}
                                    </p>
                                    <p
                                      className={`--option ${
                                        width > 800 ? "text-end" : ""
                                      } text-capitalize`}
                                    >
                                      {cart.options[option].type},{" "}
                                      {cart.options[option].VLT}%
                                    </p>
                                  </article>
                                )
                              )}
                            {cart.product.service === "accessory" &&
                              cart.note &&
                              (editRemark?.id === cart.id ? (
                                <article
                                  ref={remarkRef}
                                  className="flex items-center gap-3 remark-input"
                                >
                                  <Icon icon="ci:edit-pencil-line-01" />
                                  <input
                                    value={editRemark.remark}
                                    onChange={(e) =>
                                      setEditRemark({
                                        ...editRemark,
                                        remark: e.target.value,
                                      })
                                    }
                                  />
                                </article>
                              ) : (
                                <p
                                  className="--option two-line line-height-20 pointer"
                                  onClick={() =>
                                    setEditRemark({
                                      id: cart.id,
                                      quantity: cart.quantity,
                                      price: cart.price,
                                      remark: cart.note || "Please give me",
                                    })
                                  }
                                >
                                  <Icon icon="ci:edit-pencil-line-01" />
                                  {cart.note || "Please give me"}
                                </p>
                              ))}
                          </section>
                        )}
                      </section>
                    ))
                  ) : (
                    <article className="w-100 minh-480 flex flex-column items-center">
                      <article className="common-lottie --empty">
                        <Lottie loop={2} animationData={Empty} />
                      </article>
                      <h6 className="--loading text-center">
                        Your cart is empty
                      </h6>
                    </article>
                  )
                ) : (
                  <article className="w-100 minh-480 flex flex-column items-center">
                    <article className="common-lottie">
                      <Lottie animationData={Loading} />
                    </article>
                    <h6 className="--loading text-center">Loading ..</h6>
                  </article>
                )}
              </section>
              <TotalPriceRow
                cartInfo={cartInfo}
                loading={createOrderLoading}
                buttonTitle="Next"
              />
            </article>
            {cartInfo?.total_item > 0 && <CustomerDetail />}
          </Form>
        )}
      </Formik>
    </section>
  );
}
