import { Icon } from "@iconify/react";
import { DefaultJustint, SampleCar1 } from "../../../assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import formatNumber from "../../../helpers/format.number";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { ProductVideo } from "../../../assets/videos";
import { useNavigate } from "react-router-dom";
import { openAccessoriesModal } from "../../../redux/reducer/modalReducer";
import { getProductInfo } from "../../../redux/reducer/serviceReducer";
import { Skeleton } from "primereact/skeleton";

export default function CarProduct() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { width } = useWindowDimensions()
    const { productLoading, accessoriesList } = useSelector((state) => state.service);

    const handleNavTo = (page) => {
        navigate(`/${page}`)
    }    

    const handleViewProduct = (id) => {
        dispatch(openAccessoriesModal())
        dispatch(getProductInfo({
            id: id
        }))
    }
    
    return (
        <article className="w-100 grid center">
            <section className="car-product">
                <article className="w-100 flex justify-between items-baseline">
                    <h2>Car Accessories</h2>
                    <article className='text-with-arrow pointer' onClick={() => handleNavTo('service/accessories')}>
                        <p>View All</p>
                        <article>
                            <Icon icon="heroicons:arrow-long-right" />
                        </article>
                    </article>
                </article>
                <article className="car-grid">
                    <section className="image-grid">
                        <article className="car-frame">
                            <section className="w-100 h-100" dangerouslySetInnerHTML={{ __html: `
                                <video
                                    playsinline
                                    autoPlay 
                                    muted
                                    loop
                                    src=${ProductVideo}
                                />
                            ` }}></section>
                            <article className="car-text">
                                <section>
                                    <h6>Limited-Time Offer</h6>
                                    <span>Enjoy 32% Off</span>
                                </section>
                                <section>
                                    <h6 className="--regular">Suspension & Steering Upgrades</h6>
                                    <p>The suspension system includes components such as shocks, struts, springs, control arms, and sway bars.</p>
                                </section>
                                <section className='text-with-arrow --white mt-4 pointer' onClick={() => handleNavTo('service/accessories')}>
                                    <p>Shop Now</p>
                                    <article>
                                        <Icon icon="heroicons:arrow-long-right" />
                                    </article>
                                </section>
                            </article>
                        </article>
                    </section>
                    <section className="product-grid">
                        {!productLoading ? 
                            accessoriesList.map((product, productIndex) => (
                                <article className="product-item pointer" key={productIndex} onClick={() => handleViewProduct(product.id)}>
                                    <article className="product-frame">
                                        <LazyLoadImage
                                            src={product.media?.length > 0 ? product.media[0].url : DefaultJustint}
                                            alt={product.name.name}
                                        />
                                    </article>
                                    <article>
                                        <h5 className={`one-line ${product.price ? '' : 'mb-0'} `}>{product.name.name}</h5>
                                        <p>{product.price ? `RM ${formatNumber(product.price)}` : <span className="--view-price">View Price</span>} {product.promo_price && <span>{formatNumber(product.promo_price)}</span>}</p>
                                    </article>
                                </article> 
                            ))
                        :   
                            Array.from({ length: 6 }, (_, productSkeletonIndex) => (
                                <Skeleton className="product-item" height="334px" width="250px" key={productSkeletonIndex} />
                            ))
                        }
                    </section>
                </article>
            </section>
        </article>
    );
}