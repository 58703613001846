import { useParams } from "react-router-dom";
import WeSpecializeIn from "../../components/common/we.specialize.in";
import CarPpf from "../car_ppf";
import CarWrapping from "../car_wrapping";
import CarCoating from "../car_coating";
import ClaimWindscreen from "../claim_windscreen";
import InsuranceRenewal from "../insurance_renewal";
import CarTinted from "../car_tinted";
import Accessories from "../accessories";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProductList } from "../../redux/reducer/serviceReducer";

export default function Service() {
    const dispatch = useDispatch()
    const { service } = useParams()
    
    const { isTokenChecked, token, selectedCategory } = useSelector((state) => state.common);

    const subServiceList = {
        'car-tinted': {
            component: <CarTinted />,
            service: 'tinted'
        },
        'car-ppf': {
            component: <CarPpf />,
            service: 'ppf'
        },
        'car-wrapping': {
            component: <CarWrapping />,
            service: 'wrapping'
        },
        'car-coating': {
            component: <CarCoating />,
            service: 'coating'
        },
        'claim-windscreen': {
            component: <ClaimWindscreen />,
        },
        'accessories': {
            component: <Accessories />,
            service: 'accessory'
        },
        'insurance-renewal': {
            component: <InsuranceRenewal />,
        }
    }

    useEffect(() => {
        if(subServiceList[service]?.service && isTokenChecked) {
            dispatch(getProductList({
                service: subServiceList[service].service,
                category_id: selectedCategory
            }))
        }
    }, [service, token, isTokenChecked, selectedCategory])

    return (
        <section className={`service-page page-padding ${service === 'claim-windscreen' || service === 'insurance-renewal' || service === 'car-tinted' || service === 'car-wrapping' ? 'pb-0' : ''}`}>
            <WeSpecializeIn />
            {subServiceList[service].component}
        </section>
    );
  }
  