import { useDispatch, useSelector } from "react-redux";
import { MDBModalBody, MDBModalFooter } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  DefaultJustint,
  LargeDefaultJustint,
  SampleProduct1,
} from "../../assets/images";
import ModalHeader from "../header/modal.header";
import FullModal from "../modal/full.modal.box";
import {
  closeAccessoriesModal,
  openLoginModal,
} from "../../redux/reducer/modalReducer";
import useWindowDimensions from "../hook/use.window.dimensions";
import { addCart, getCartInfo } from "../../redux/reducer/cartOrderReducer";
import { useToast } from "./common.toast";
import { useLocation } from "react-router-dom";
import { getCartCount } from "../../redux/reducer/commonReducer";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Input from "../element/input";
import { useState } from "react";

export default function AccessoriesModal() {
  const toast = useToast();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const [remark, setRemark] = useState("");

  const { token, username } = useSelector((state) => state.common);
  const { accessoriesInfo, productInfoLoading } = useSelector(
    (state) => state.service
  );
  const { addCartLoading } = useSelector((state) => state.cart_order);
  const { isOpenAccessoriesModal } = useSelector((state) => state.modal);

  const productDetails = [
    {
      label: "Brand",
      value: accessoriesInfo.brands,
    },
    {
      label: "Model",
      value: accessoriesInfo.car_models,
    },
    {
      label: "Year",
      value: accessoriesInfo.properties?.model_year,
    },
  ];

  const handleCloseModal = () => {
    dispatch(closeAccessoriesModal());
  };

  const handleAddCart = (id) => {
    if (token && username) {
      dispatch(
        addCart({
          product_id: [id],
          quantity: 1,
          note: remark,
        })
      )
        .unwrap()
        .then((res) => {
          if (pathname === "/cart") {
            dispatch(getCartInfo());
          }
          handleCloseModal();
          dispatch(getCartCount());
          toast.success("Add to cart successfully.");
        })
        .catch((ex) => {
          if (ex && ex.response?.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                toast.error(errors[item][0]);
              });
            }
          }
        });
    }
  };

  const handleLogin = () => {
    handleCloseModal();
    dispatch(openLoginModal());
  };

  const handleClickEnquiry = (product) => {
    const phoneNumber = "60122334402";
    const message = `Hi, I would like to inquire about the product ${product}. Could you provide more details or assistance?`;
    const whatsappURL = `https://wa.me/`;

    window.open(
      whatsappURL + phoneNumber + "?text=" + encodeURIComponent(message)
    );
  };

  const handleShareProduct = () => {
    const productUrl = `${process.env.REACT_APP_URL}/service/accessories?product=${accessoriesInfo.id}`;
    const productName = accessoriesInfo.name?.name;

    if (navigator.share) {
      navigator
        .share({
          url: productUrl,
        })
        .then(() => toast.success("Share successfully."))
        .catch((error) => {
          const fallbackMessage = `Check out this product: ${productName}\n${productUrl}`;
          navigator.clipboard.writeText(fallbackMessage).then(() => {
            toast.error(
              "Share unsuccessfully. Product link copied to clipboard. You can share it manually."
            );
          });
        });
    } else {
      const fallbackMessage = `Check out this product: ${productName}\n${productUrl}`;
      navigator.clipboard.writeText(fallbackMessage).then(() => {
        toast.error(
          "Share unsuccessfully. Product link copied to clipboard. You can share it manually."
        );
      });
    }
  };

  return (
    <FullModal
      staticBackdrop={true}
      show={isOpenAccessoriesModal}
      backButton={handleCloseModal}
      screenSize={width >= 770 ? "xl" : "fullscreen-xl-down"}
      className="overflow-hidden"
      dialogClassName="width-500"
      contentClassName="br-34"
      content={
        <>
          <ModalHeader
            className="br-34 fixed"
            backTo={handleCloseModal}
            shareTo={handleShareProduct}
            title="Product Details"
          />
          <MDBModalBody>
            <article className="accessories-modal">
              <Swiper
                pagination={{
                  type: "fraction",
                }}
                rewind={true}
                navigation={true}
                grabCursor={true}
                slidesPerView="auto"
                modules={[Pagination, Navigation]}
              >
                {/* <section className="accessories-frame"> */}
                {accessoriesInfo.media?.length > 0 ? (
                  accessoriesInfo.media.map((media, mediaIndex) => {
                    const isVideoFile = [
                      "mp4",
                      "MP4",
                      "mov",
                      "MOV",
                      "webm",
                      "WEBM",
                    ].includes(media.url?.split(".").pop());

                    return (
                      <SwiperSlide
                        key={mediaIndex}
                        className="accessories-frame"
                      >
                        {isVideoFile ? (
                          <video src={media.url} controls />
                        ) : (
                          <LazyLoadImage
                            src={media.url}
                            alt={accessoriesInfo.name?.name}
                          />
                        )}
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <SwiperSlide className="accessories-frame">
                    <LazyLoadImage
                      src={LargeDefaultJustint}
                      alt={accessoriesInfo.name?.name}
                    />
                  </SwiperSlide>
                )}
                {/* </section> */}
              </Swiper>
              <section className="accessories-name-price">
                <h6>{accessoriesInfo.name?.name}</h6>
                <h5
                  className={isNaN(accessoriesInfo.price) ? "transparent" : ""}
                >
                  RM{" "}
                  {isNaN(accessoriesInfo.price)
                    ? 0
                    : parseFloat(accessoriesInfo.price).toLocaleString()}
                </h5>
              </section>
              <section className="accessories-flex">
                <article
                  className={`accessories-detail ${
                    !accessoriesInfo.description ||
                    accessoriesInfo.description === "<p><br></p>"
                      ? "w-100"
                      : ""
                  }`}
                >
                  <h6 className="--title mb-2">Product Details</h6>
                  {productDetails.map((product, productIndex) => (
                    <article className="product-row" key={productIndex}>
                      <h6>{product.label}</h6>
                      {product.value && Array.isArray(product.value) ? (
                        <article className="flex flex-wrap justify-end gap-2">
                          {product.value?.length > 0 ? (
                            product.value.map(
                              (productValue, productValueIndex) => (
                                <p key={productValueIndex}>
                                  {productValue?.name || productValue}
                                  {productValueIndex + 1 !==
                                  product.value?.length ? (
                                    <span>,</span>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              )
                            )
                          ) : (
                            <p>All</p>
                          )}
                        </article>
                      ) : (
                        <p>{product.value || "-"}</p>
                      )}
                    </article>
                  ))}
                </article>
                {accessoriesInfo.description &&
                  accessoriesInfo.description !== "<p><br></p>" && (
                    <article className="accessories-desc">
                      <h6 className="--title mb-2">Product Description</h6>
                      <hr />
                      <article
                        className="desc"
                        dangerouslySetInnerHTML={{
                          __html: accessoriesInfo.description,
                        }}
                      ></article>

                      {/* {productDetails.description.map((desc, descIndex) => (
                          <article className="desc-row mb-3" key={descIndex}>
                          </article>
                        ))} */}
                    </article>
                  )}
              </section>
              {/* {width < 500 && (
                <section className="modal-footer mobile">
                  <section className="footer-remark">
                    <h6>Remark :</h6>
                    <input name="remark" values="abc" />
                  </section>
                  <article
                    className={`footer-button ${
                      addCartLoading ? "--disabled" : ""
                    }`}
                    onClick={() =>
                      !addCartLoading
                        ? handleAddCart(accessoriesInfo.id)
                        : undefined
                    }
                  >
                    <h5>
                      {!isNaN(accessoriesInfo.price)
                        ? "Add To Cart"
                        : "View Price"}
                    </h5>
                  </article>
                </section>
              )} */}
            </article>
          </MDBModalBody>
          <MDBModalFooter
            className={`${width < 500 ? "mobile" : ""} ${
              isNaN(accessoriesInfo.price) || accessoriesInfo.price === 0
                ? "pt-0"
                : ""
            }`}
            style={{
              position: productInfoLoading
                ? "absolute"
                : width < 500
                ? "fixed"
                : "sticky",
            }}
          >
            {accessoriesInfo.price && accessoriesInfo.price > 0 ? (
              <section className="footer-remark">
                <h6>Remark :</h6>
                <input
                  value={remark}
                  placeholder="Add any special instructions or notes (optional)"
                  onChange={(e) => setRemark(e.target.value)}
                />
              </section>
            ) : (
              ""
            )}
            <section
              className={`footer-button ${addCartLoading ? "--disabled" : ""}`}
              onClick={() =>
                !addCartLoading
                  ? token && username
                    ? accessoriesInfo.price && accessoriesInfo.price > 0
                      ? handleAddCart(accessoriesInfo.id)
                      : handleClickEnquiry(accessoriesInfo.name?.name)
                    : handleLogin()
                  : undefined
              }
            >
              <h5>
                {!isNaN(accessoriesInfo.price)
                  ? accessoriesInfo.price && accessoriesInfo.price > 0
                    ? "Add To Cart"
                    : "Click For Enquiry"
                  : "View Price"}
              </h5>
            </section>
          </MDBModalFooter>
        </>
      }
    />
  );
}
