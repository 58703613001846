import { useDispatch, useSelector } from "react-redux";
import { closeLoginModal } from "../../../redux/reducer/modalReducer";
import FullModal from "../../../components/modal/full.modal.box";
import { MDBModalBody } from "mdb-react-ui-kit";
import Button from "../../../components/element/button";
import { Form, Formik } from "formik";
import { getToken, getUsername, getUserRank, getUserRole, login, sendOtp } from "../../../redux/reducer/commonReducer";
import { useEffect, useState } from "react";
import InputOtp from "../../../components/element/input.otp";
import * as Yup from 'yup';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Logo, Support } from "../../../assets/images";
import { useToast } from "../../../components/common/common.toast";

export default function LoginModal() {
    const toast = useToast()
    const dispatch = useDispatch()
    const { isOpenLoginModal } = useSelector((state) => state.modal);
    const { loginLoading, sendOtpLoading } = useSelector((state) => state.common);

    const [ countDown, setCountDown ] = useState(null);
    const [ countDownId, setCountDownId ] = useState(null);
    const [ tab, setTab ] = useState('sign up');
    const [ firstSend, setFirstSend ] = useState(true);

    const loginForm = {
        username: '',
        verification_code: '',
    }

    const loginSchema = Yup.object().shape({
        username: Yup.string()
            .matches(/^[1-9]\d{7,9}$/, 'Username must be a valid phone number')
            .required('Username is required'),
        verification_code: Yup.string()
            .min(6, "OTP must be at least 6 characters")
            .required("Verification code is required"),
    });

    const loginFormList = [
        {
            label: 'Phone Number',
            name: 'username',
            prefix: true
        },
        {
            label: 'OTP',
            name: 'verification_code' 
        },
    ]

    const handleCloseModal = () => {
        dispatch(closeLoginModal())
    }

    const handleContactSupport = () => {
        const phoneNumber = '60103640667';
        const message = "Hi, I'd like to register an account on justint.redpeople.io.";
        const whatsappURL = `https://wa.me/`;

        window.open(whatsappURL + phoneNumber + '?text=' + encodeURIComponent(message))
    }

    const handleSubmit = (values, setFieldError) => {
        dispatch(login({
            username: `+60${values.username}`,
            verification_code: values.verification_code,
            type: 'login'
        }))
        .unwrap()
        .then((res) => {
            localStorage.setItem('token', res.data.token)
            localStorage.setItem('username', res.data.username)
            localStorage.setItem('rank', res.data.user_rank)
            localStorage.setItem('role', res.data.role)

            dispatch(getToken())
            dispatch(getUsername())
            dispatch(getUserRank())
            dispatch(getUserRole())
            dispatch(closeLoginModal())
            toast.success('Login successfully.')
        })
        .catch((ex) => {
            if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        if(errors[item][0] === 'Phone number not found' || errors[item][0] === 'Account not exist.') {
                            setFieldError(item, <>This user is not registered. Please <span onClick={() => handleContactSupport()}>contact support</span> to create your account.</>)
                            setFieldError('verification_code', "")

                            return
                        } else {
                            setFieldError(item, errors[item][0]);
                        }
                    });
                }
            }
        })
    }

    const handleSendOtp = (values, setFieldValue, setFieldError) => {
        if (firstSend) {
            setFirstSend(false);
        }

        dispatch(sendOtp({
            username: `+60${values.username}`
        }))
        .unwrap()
        .then((res) => {
            setFieldValue(values, values);

            if (countDown === null) {
                setCountDown(60);
                const timer = setInterval(() => {
                setCountDown((prevCountdown) => prevCountdown - 1);
                }, 1000);
                setCountDownId(timer);
            }
        })
        .catch((ex) => {
            if (ex && ex.response?.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        if(errors[item][0] === 'Account not exist') {
                            setFieldError(item, <>This user is not registered. Please <span onClick={() => handleContactSupport()}>contact support</span> to create your account.</>);
                        } else {
                            setFieldError(item, errors[item][0]);
                        }
                    });
                }
            }
        })
    }

    const formatCountDown = (time) => {
        if (time === 60) {
          return `( ${time}s )`;
        } else {
          return `( ${time}s )`;
        }
    };

    useEffect(() => {
        if (countDown === 0) {
          clearInterval(countDownId);
          setCountDown(null);
        }
    }, [countDown, countDownId]);

    return (
        <FullModal
        staticBackdrop={true}
        show={isOpenLoginModal}
        backButton={handleCloseModal}
        screenSize="xl"
        className="overflow-hidden"
        contentClassName="login-modal"
        content={
          <>
            <MDBModalBody>
              <article className="login-modal">
                <section className="login-header">
                    <article className="logo-frame">
                        <LazyLoadImage src={Logo} />    
                    </article>
                    {tab === 'sign up' ? <h2>Welcome to Justint</h2> : <h2>Welcome Back!</h2>}
                    <p>Sign up or log in to access personalized services, manage orders, and enjoy exclusive offers just for you.</p>
                    <article className="login-tab">
                        <section 
                            className={`tab ${tab === 'sign up' ? '--active' : ''}`}
                            onClick={() => setTab('sign up')} 
                        >
                            <p>Sign Up</p>
                        </section>
                        <section 
                            className={`tab ${tab === 'login' ? '--active' : ''}`}
                            onClick={() => {
                                clearInterval(countDownId)
                                setCountDown(null);
                                setFirstSend(true)
                                setTab('login')
                            }}
                        >
                            <p>Login</p>
                        </section>
                    </article>
                </section>
                <section className="login-body">
                    {tab === 'sign up' ?
                        <article className="sign-up">
                            <article className="support-frame">
                                <LazyLoadImage src={Support} />    
                            </article>
                            <h4>Create Account via Support</h4>
                            <p>To get started, please contact our support team. They will assist you with creating your account so you can log in.</p>
                            <Button btnClassName="login-button" type="button" onClick={() => handleContactSupport()}>Contact Support</Button>
                        </article>
                    :
                        <Formik
                            initialValues={loginForm}
                            validationSchema={loginSchema}
                            enableReinitialize={true}
                            onSubmit={(values, { setFieldError }) => {
                                handleSubmit(values, setFieldError);
                            }}
                        > 
                            {({values, errors, touched, setFieldValue, setFieldError, isValid}) => (
                                <Form className="login-form">
                                    {loginFormList.map((form, formIndex) => (
                                        <article className="login-input" key={formIndex}>
                                            <h6>{form.label}</h6>
                                            {form.prefix ?
                                                <>
                                                    <section className="phone-container">
                                                        {/* <article className="phone-wrap"> */}
                                                            <p>+60</p>
                                                            <input
                                                                name={form.name}
                                                                onChange={(e) => {
                                                                    setFieldValue(form.name, e.target.value)
                                                                }}
                                                            />
                                                        {/* </article> */}
                                                        <Button 
                                                            className="h-100"
                                                            btnClassName="send-otp-button"
                                                            disabled={!values.username || errors['username'] || sendOtpLoading}
                                                            onClick={() => {
                                                                if(countDown === null) {
                                                                    handleSendOtp(values, setFieldValue, setFieldError)
                                                                }
                                                            }}
                                                        >
                                                            <p>{sendOtpLoading ? 'Sending ..' 
                                                            : countDown === null && firstSend ? 'Send OTP' 
                                                            : countDown === null && !firstSend ? 'Send OTP'
                                                            : `Resend in ${formatCountDown(countDown)}`
                                                            }</p>
                                                        </Button>
                                                    </section>
                                                    <section>
                                                        {errors && errors['username'] ? (
                                                        <div className="element _errors text-right no-padding error-message ms-0">
                                                            {errors['username']}
                                                        </div>
                                                        ) : null}
                                                    </section>
                                                </>
                                            :
                                                <InputOtp
                                                    name={form.name}
                                                />
                                            }
                                        </article>
                                    ))}
                                    <Button btnClassName="login-button" type="submit" disabled={!values['username'] || !isValid || loginLoading}>Login</Button>
                                    {/* <p>How to become our member ── <span>Click here!</span></p> */}
                                </Form>
                            )}
                        </Formik>
                    }
                </section>
              </article>
            </MDBModalBody>
          </>
        }
      />
    );
  }
  